import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import { useAppSelector, useSaveJob } from '../../../hooks';
import {
    JiraTicketType,
    JobType,
    PlanType,
    selectIsLimitedAccess,
    selectIsReadOnly,
    selectUiActiveStep,
    useCreateNewJiraTicketMutation,
  } from '../../../reducers';
  import { useHomeContext } from '../HomeContext';
  import { StrategiesGridValidationError, useValidateStrategiesGrid } from '../Strategies/StrategiesGrid';
  import { jobSendToJiraValidationSchema } from '../../../application/common';
  interface SendToJiraPropsType {
    job: JobType;
    id: string;
  }
  
export const SendToJira = (props: SendToJiraPropsType) => {
    const activeStep = useAppSelector(selectUiActiveStep);
    const isLimitedAccess = useAppSelector(selectIsLimitedAccess);
    const isReadOnly = useAppSelector(selectIsReadOnly);
    const { getBenchmarkName, getClientName, getFplName, getIncumbentName, getPlanNames } = useHomeContext();
    const { validateStrategiesGrid } = useValidateStrategiesGrid();
    const [createNewJiraTicket, createNewJiraTicketResult] = useCreateNewJiraTicketMutation();
    const { saveJob } = useSaveJob();
    
    const extractJiraUser = (workEmail: string) =>
        workEmail.includes('|') ? workEmail.split('|')[1] : workEmail.split('@')[0];

    const getPlanMandateSize = (plans?: PlanType[]) =>
        plans ? plans.map(p => p.planMandateSize).reduce((acc, cur) => acc + cur) : 0;

    const handleSendToJira = async (job: JobType) => {
        try {
          if (!job.isCanceled) {
    
            jobSendToJiraValidationSchema.validateSync(job);
            validateStrategiesGrid(job);
    
            await saveJob({ job });
            const newJiraTicket: JiraTicketType = {
              isConsultant: isLimitedAccess,
              assignee: '',
              description: `${job.jobName} [ ${job.jobID} ]`,
              jobID: job.jobID,
              participants: extractJiraUser(job.requestorEmail),
              reporter: extractJiraUser(job.primaryConsultantEmail),
              summary: job.jobName,
              dueDate: job.dueDate,
              meetingDate: job.meetingDate,
              vehiclesUnderConsideration: job.vehiclesUnderConsideration,
              benchmark: getBenchmarkName(job.fkRequestedBmk, job.strategies),
              clientName: getClientName(job.clientShortCode),
              planNames: getPlanNames(job.plans),
              mandateSize: getPlanMandateSize(job.plans),
              fpl: getFplName(job.strategies),
              incumbent: getIncumbentName(job.strategies),
              commentsForResearch: job.notesForRA,
              searchTemplate: job.searchTemplate,
              searchSourceOfCandidate: job.searchSourceOfCandidate,
              portfolioMgmtStrategy: job.portfolioMgmtStrategy,
            };
            createNewJiraTicket(newJiraTicket)
              .unwrap()
              .then(() => {
                toast.success('Jira ticket created successfully');
              })
              .catch(({ data }) => console.error(`Job not sent to Jira. Error: ${data.error}`));
          }
        } catch (err: any) {
          if (err instanceof StrategiesGridValidationError) err.errors.forEach((msg) => toast.error(msg));
          toast.error(`Job not sent to Jira. Error: ${err.status || err.message}`);
        }
      };

    return (
      <Button
        id="send-to-jira-btn"
        disabled={isReadOnly || createNewJiraTicketResult.isLoading}
        form={`form-${activeStep + 1}`}
        fullWidth
        onClick={() => handleSendToJira(props.job)}
        type="submit"
        variant="contained"
      >
        Send to Jira
      </Button>
    );
};
