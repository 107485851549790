import { Moment } from 'moment';
import * as Globals from '../application/common';
import { apiSlice } from './apiSlice';
import { toast } from 'react-toastify';

export interface JiraTicketType {
  assignee: string;
  description: string;
  isConsultant: boolean;
  jobID: string;
  participants: string;
  reporter: string;
  summary: string;
  dueDate?: Moment | string | null;
  meetingDate?: Moment | null;
  vehiclesUnderConsideration: string[];
  benchmark?: string;
  clientName?: string;
  planNames: String[];
  mandateSize: number;
  fpl?: String;
  incumbent?: String;
  commentsForResearch: String;
  searchTemplate: String;
  searchSourceOfCandidate: String;
  portfolioMgmtStrategy: String;
}

export const emptyJiraTicket: JiraTicketType = {
  assignee: '',
  description: '',
  isConsultant: false,
  jobID: '',
  participants: '',
  reporter: '',
  summary: '',
  dueDate: '',
  meetingDate: null,
  vehiclesUnderConsideration: [],
  benchmark: '',
  clientName: '',
  planNames: [],
  mandateSize: 0,
  fpl: '',
  incumbent: '',
  commentsForResearch: '',
  searchTemplate: '',
  searchSourceOfCandidate: '',
  portfolioMgmtStrategy: '',
};

export const extendedApiJiraIntegrationSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createNewJiraTicket: builder.mutation({
      transformErrorResponse: (response) => {
        toast.error(`Create new Jira Ticket action failed. Error: ${response.status}`);
        return response;
      },
      query: (jiraTicket: JiraTicketType) => ({
        url: `${Globals.API.endpoints.Jira}/${jiraTicket.jobID}`,
        method: 'POST',
        body: jiraTicket,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Job', id: arg.jobID }],
    }),
  }),
});

export const { useCreateNewJiraTicketMutation } = extendedApiJiraIntegrationSlice;
